import { useFormik } from 'formik';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Loading from '../../utils/Loading';
import { useState } from 'react';

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Username Required';
  }

  if (!values.password) {
    errors.password = 'Password Required';
  }

  if (!values.userRole) {
    errors.userRole = 'User Role Must be defined';
  }

  return errors;
};
const NewUser = () => {
  const [loading, setLoading] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      userRole: 'user',
    },
    validate,
    onSubmit: async (values, actions) => {
      setLoading(true);
      const data = {
        username: values.username,
        password: values.password,
        userRole: values.userRole,
      };
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/user/signup`, data)
        .then((response) => {
          if (response.data.token) {
            setSuccessMessage('User Registration Successfull');
            setErrorMessage('');
            setLoading(false);
            actions.resetForm({
              values: {
                username: '',
                password: '',
                userRole: 'user',
              },
            });
          } else if (response.data.error) {
            console.log('Inside else');
            console.log('Error', response);
            setErrorMessage(response.data.error);
            setSuccessMessage('');
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log('Inside Catch', error);
          setErrorMessage(error);
          setLoading(false);
        });
    },
  });
  return (
    <Container className="shadow p-3 mb-5 mt-5 bg-white rounded">
      <h2>Register a New User</h2>
      {errorMessage !== '' ? <Alert>{errorMessage}</Alert> : ''}
      {successMessage !== '' ? <Alert>{successMessage}</Alert> : ''}

      <Form onSubmit={formik.handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6">
            <Form.Label>Username</Form.Label>
            <Form.Control
              id="username"
              name="username"
              type="text"
              placeholder="Enter a Unique Username"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
            />
            {formik.touched.username && formik.errors.username ? (
              <Alert>{formik.errors.username}</Alert>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Password</Form.Label>
            <Form.Control
              id="password"
              name="password"
              type="password"
              placeholder="Enter password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <Alert>{formik.errors.password}</Alert>
            ) : null}{' '}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12">
            <Form.Select
              id="userRole"
              name="userRole"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.userRole}
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
              <option value="manager">Manager</option>
            </Form.Select>
            {formik.touched.userRole && formik.errors.userRole ? (
              <Alert>{formik.errors.userRole}</Alert>
            ) : null}{' '}
          </Form.Group>
        </Row>
        {loading && <Loading />}
        <Button type="submit" className="mt-2" disabled={loading}>
          Register User
        </Button>
      </Form>
    </Container>
  );
};

export default NewUser;
