import { useFormik } from 'formik';

import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Loading from '../../utils/Loading';
import { useState } from 'react';
import ErrorModal from '../erroComponents/ErrorModal';

const validate = (values) => {
  const errors = {};
  if (!values.stationName) {
    errors.stationName = 'Station Name Required';
  }

  if (!values.stationLocation) {
    errors.stationLocation = 'Station Location Is Required';
  }

  if (!values.stationManagers) {
    errors.stationManagers = 'Station Manager is Required';
  }

  return errors;
};
const NewStation = () => {
  const [loading, setLoading] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const formik = useFormik({
    initialValues: {
      stationName: '',
      stationLocation: '',
      stationManagers: '',
    },
    validate,
    onSubmit: async (values, actions) => {
      console.log(values.stationName)
      setLoading(true);
      const data = {
        stationName: values.stationName,
        stationLocation: values.stationLocation,
        stationManagers: values.stationManagers,
      };
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/station/new-station`, data)
        .then((response) => {
          if (response.data === 'Registered Successfully') {
            setSuccessMessage('Charging Station Registered Successfully');
            setErrorMessage('');
            setLoading(false);
            actions.resetForm({
              values: {
                stationName: '',
                stationLocation: '',
                stationManagers: '',
              },
            });
            // Reloading the page so, this station can be seen on the vehicle registration dropdown
            // this will be fixed using redux later
            window.location.reload(false);
          } else {
            setErrorMessage(response.data);
            console.log(response.data)
            setSuccessMessage('');
            setLoading(false);
          }
        })
        .catch((error) => {
          setErrorMessage(error);
          setLoading(false);
        });
    },
  });
  return (
    <Container className="shadow p-3 mb-5 mt-5 bg-white rounded">
      <h2>Register a New Charging Station</h2>
      {errorMessage !== '' ? <Alert>{errorMessage}</Alert> : ''}
      {successMessage !== '' ? <Alert>{successMessage}</Alert> : ''}
      <Form onSubmit={formik.handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6">
            <Form.Label>Station name</Form.Label>
            <Form.Control
              id="stationName"
              name="stationName"
              type="text"
              placeholder="Enter a Unique Station Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stationName}
            />
            {formik.touched.stationName && formik.errors.stationName ? (
              <Alert>{formik.errors.stationName}</Alert>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Station Location</Form.Label>
            <Form.Control
              id="stationLocation"
              name="stationLocation"
              type="text"
              placeholder="Enter Station Location"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stationLocation}
            />
            {formik.touched.stationLocation && formik.errors.stationLocation ? (
              <Alert>{formik.errors.stationLocation}</Alert>
            ) : null}{' '}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12">
            <Form.Label>Station Manager</Form.Label>
            <Form.Control
              id="stationManagers"
              name="stationManagers"
              type="text"
              placeholder="Enter Station Manager Username"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stationManagers}
            />
            {formik.touched.stationManagers && formik.errors.stationManagers ? (
              <Alert>{formik.errors.stationManagers}</Alert>
            ) : null}{' '}
          </Form.Group>
        </Row>
        {loading && <Loading />}
        <Button type="submit" className="mt-2" disabled={loading}>
          Add New Station
        </Button>
      </Form>
    </Container>
  );
};

export default NewStation;
