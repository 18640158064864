import axios from 'axios';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Loading from '../../utils/Loading';
//redux
import { useDispatch } from 'react-redux';
import { login } from '../../features/login/loginSlice';
import { useNavigate } from 'react-router-dom';
import { checkLoggedIn } from '../../utils/checkLoggedIn';

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Username required';
  }

  if (!values.password) {
    errors.password = 'Password required';
  }
  return errors;
};

const Login = () => {
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState();
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (checkLoggedIn()) {
      return navigate('/dashboard');
    }
  }, [authorized, navigate]);
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validate,
    onSubmit: async (values) => {
      setLoading(true);
      const data = { username: values.username, password: values.password };
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/user/login`, data)
        .then((response) => {
          setAuthorized(true);
          dispatch(
            login({
              username: response.data.username,
              isDev: response.data.isDev,
              userRole: response.data.userRole,
              isLoggedIn: true,
            }),
          );
          localStorage.setItem('login', 'authenticated');
          setLoading('');
        })
        .catch(function (error) {
          setAuthorized(false);
          setLoading('');
        });
    },
  });
  return (
    <Container className="center shadow p-3 mb-5 bg-white rounded" fluid="md">
      <h2>VCSMS</h2>
      <Form onSubmit={formik.handleSubmit}>
        {authorized === false && (
          <Alert className="text-center" variant="warning">
            Wrong Credentials
          </Alert>
        )}
        <Form.Group className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            id="username"
            name="username"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.username}
            placeholder="Username"
          />
          {formik.touched.username && formik.errors.username ? (
            <Alert variant="warning">{formik.errors.username}</Alert>
          ) : null}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            id="password"
            name="password"
            type="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
            placeholder="Password"
          />
          {formik.touched.password && formik.errors.password ? (
            <Alert variant="warning">{formik.errors.password}</Alert>
          ) : null}
        </Form.Group>
        {loading && <Loading />}
        <Button variant="primary" type="submit" disabled={loading}>
          Submit
        </Button>
      </Form>
    </Container>
  );
};
export default Login;
