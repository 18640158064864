import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = { username: '', isDev: '', userRole: '',isLoggedIn:false};

export const loginSlice = createSlice({
  name: 'login',
  initialState:{value:initialStateValue},
  reducers: {
    //actions
    login:(state,action)=>{
        state.value= action.payload
    }
  },
});

export const {login}= loginSlice.actions
export default loginSlice.reducer;
