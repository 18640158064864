import { useParams } from 'react-router-dom';
import { Input } from '@chakra-ui/react';
import { useState } from 'react';

import useFetch from '../../hooks/useFetch';
import Loading from '../../utils/Loading';
import TempoImage from '../../assets/tempo.jpg';
import {
  Card,
  CardBody,
  CardFooter,
  Image,
  Stack,
  Heading,
  Text,
  Divider,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
const Station = () => {
  const { stationName } = useParams();
  const [search, setSearch] = useState('');

  const { data, loading } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/station/${stationName}`,
  );
  data && console.log(data);
  return (
    <div>
      <div className="searchContainer">
        <Input
          placeholder="Search Tempo By Number"
          onChange={(e) => setSearch(e.target.value.toLocaleLowerCase())}
        />
      </div>

      <div className="tempoStationContainer">
        {data ? (
          <>
            {data.registeredVehicles.filter((vehicle) => {
              return search.toLowerCase() === ''
                ? vehicle
                : vehicle.vehicleDetails.vehicleNumber.toLowerCase().includes(search);
            }).map((vehicle) => (
              <>
                <Card maxW="sm" m={4}>
                  <CardBody>
                    <Image
                      src={TempoImage}
                      alt="Tempo Icon"
                      borderRadius="lg"
                    />
                    <Stack mt="6" spacing="3">
                      <Heading size="md">
                        Vehicle Number:
                        {vehicle.vehicleDetails.vehicleNumber}
                      </Heading>
                      <Text>
                        Per Trip Price : 450
                        <br />
                        Number of Trip Today: 10
                      </Text>
                      <Text color="blue.600" fontSize="2xl">
                        Today's Earning : Rs 4500
                      </Text>
                    </Stack>
                  </CardBody>
                  <Divider />
                  <CardFooter>
                    <ButtonGroup spacing="2">
                      <Button variant="solid" colorScheme="blue">
                        +1
                      </Button>
                      <Button variant="solid" colorScheme="red">
                        -1
                      </Button>
                      <Button variant="outline" colorScheme="blue">
                        Edit
                      </Button>
                      <Button variant="outline" colorScheme="red">
                        Reset
                      </Button>
                    </ButtonGroup>
                  </CardFooter>
                </Card>
              </>
            ))}
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Station;
