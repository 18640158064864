import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import NewStation from '../../components/forms/NewStation';
import NewUser from '../../components/forms/NewUser';
import NewVehicle from "../../components/forms/NewVehicle"
const Register = () => {
  // const [key, setKey] = useState('home');

  return (
    <Tabs
      defaultActiveKey="newStation"
      id="fill-tab-example"
      className="mb-3 mt-4"
      fill
    >
      <Tab eventKey="newStation" title="New Station">
        <NewStation/>
      </Tab>
      <Tab eventKey="newUser" title="New User">
        <NewUser/>
      </Tab>
      <Tab eventKey="newVehicle" title="New Vehicle">
        <NewVehicle/>
      </Tab>
    </Tabs>
  );
};

export default Register;
