import {
  Card,
  CardBody,
  CardFooter,
  Image,
  Stack,
  Heading,
  Text,
  Divider,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ChargingStationImage from '../../assets/chargingStation.png';
const CardComponent = ({
  header,
  button1,
  button2,
  button1Event,
  button2Event,
  stationName
}) => {
  return (
    <div>
      <Card maxW="sm" m={4}>
        <CardBody m={2}>
          <Image src={ChargingStationImage} alt="Tempo Image" borderRadius="lg" />
          <Stack mt="6" spacing="3">
            <Heading size="md">{header}</Heading>
          </Stack>
        </CardBody>
        <Divider />
        <CardFooter>
          <ButtonGroup spacing="2">
            <Link to={`/station/${stationName}`}>
              <Button variant="solid" colorScheme="blue" onClick={button1Event}>
                {button1}
              </Button>
            </Link>
            <Button variant="ghost" colorScheme="blue" onClick={button2Event}>
              {button2}
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </div>
  );
};

export default CardComponent;
