import axios from 'axios';
import CardComponent from '../../components/reusables/CardComponent';
import { Flex, Spacer, Center, Square } from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Loading from '../../utils/Loading';

const Dashboard = () => {
  const [allStation, setAllStation] = useState(null);
  const [search, setSearch] = useState('');
  console.log(search);
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/station`)
        .then((response) => {
          setAllStation(response.data);
        });
    };
    fetchData();
  }, []);
  allStation && console.log(allStation);
  const handleView = (id) => {
    console.log('The id from select ', id);
  };
  const handleEdit = (id) => {
    console.log('The id from edit ', id);
  };
  return (
    <div>
      <div className="searchContainer">
        <Input
          placeholder="Search for station"
          onChange={(e) => setSearch(e.target.value.toLocaleLowerCase())}
        />
      </div>
      <div className="tempoStationContainer">
        {allStation ? (
          allStation
            .filter((station) => {
              return search.toLowerCase() === ''
                ? station
                : station.stationName.toLowerCase().includes(search);
            })
            .map((station) => (
              <CardComponent 
                key={station._id}
                header={station.stationName}
                button1="Go to Station"
                button2="Edit"
                button1Event={() => handleView(station._id)}
                button2Event={() => handleEdit(station._id)}
                stationName={station.stationName}
              />
            ))
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
