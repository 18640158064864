import './App.css';
import { Routes, Route } from 'react-router-dom';
import Navigation from "./components/navbar/Navigation"
import Dashboard from './pages/dashboard/Dashboard';
import Register from './pages/register/Register';
import Login from './pages/login/Login';
import NotFound from './pages/NotFound/NotFound';
import { useSelector } from 'react-redux';
import ProtectedRoute from './utils/ProtectedRoute';
import { checkLoggedIn } from './utils/checkLoggedIn';
import Station from './pages/station/Station';
function App() {
  const loginStatus = useSelector((state) => state.login.value)
  return (
    <div className="App">
      {checkLoggedIn() && <Navigation/>}
        <Routes>
          <Route index element={<Login />} />
          <Route path="register" element={<ProtectedRoute Component={Register}/>} />
          <Route path="dashboard" element={<ProtectedRoute Component={Dashboard}/>} />
          <Route path="/station/:stationName" element={<Station/>}/>
          <Route path="*" element={<NotFound />} />
        </Routes>
    </div>
  );
}

export default App;
