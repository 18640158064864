import { useFormik } from 'formik';

import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Loading from '../../utils/Loading';
import { useEffect, useState } from 'react';


const validate = (values) => {
  const errors = {};
  if (!values.driverName) {
    errors.driverName = 'Station Name Required';
  }

  if (!values.licenseNumber) {
    errors.licenseNumber = 'License number is required';
  }

  if (!values.vehicleNumber) {
    errors.vehicleNumber = 'Enter vehicle number';
  }
  if (!values.ownerName) {
    errors.ownerName = 'Owner of the vehicle';
  }
  if (!values.reportingStationName) {
    errors.reportingStationName = 'Enter reporting station name';
  }

  return errors;
};
const NewVehicle = () => {
  const [loading, setLoading] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [stations, setStations] = useState(null);
  const [fetchOptions,setFetchOptions] = useState(false)
  useEffect(() => {
    const fetchStations = async () => {
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/station`)
        .then((response) => {
          setStations(response.data);
        });
    };
    fetchStations();
  }, [fetchOptions]);
  const formik = useFormik({
    initialValues: {
      driverName: '',
      licenseNumber: '',
      vehicleNumber: '',
      ownerName: '',
      reportingStationName: '',
    },
    validate,
    onSubmit: async (values, actions) => {
      console.log('Submitted', values);
      setLoading(true);
      const data = {
        driverDetails: {
          driverName: values.driverName,
          licenseNumber: values.licenseNumber,
        },
        vehicleDetails: {
          vehicleNumber: values.vehicleNumber,
          ownerName: values.ownerName,
        },
        reportingStationName: values.reportingStationName,
      };
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/register/vehicle/new`,
          data,
        )
        .then((response) => {
          if (response.data) {
            setSuccessMessage('New Vehicle Added to the station');
            setErrorMessage('');
            setLoading(false);
            actions.resetForm({
              values: {
                driverName: '',
                licenseNumber: '',
                vehicleNumber: '',
                ownerName: '',
                reportingStationName: '',
              },
            });
          } else {
            setErrorMessage(response.data);
            console.log(response.data);
            setSuccessMessage('');
            setLoading(false);
          }
        })
        .catch((error) => {
          setErrorMessage(error);
          setLoading(false);
        });
    },
  });

  return (
    <Container className="shadow p-3 mb-5 mt-5 bg-white rounded">
      <h2>Register a New Vehicle</h2>
      {errorMessage !== '' ? <Alert>{errorMessage}</Alert> : ''}
      {successMessage !== '' ? <Alert>{successMessage}</Alert> : ''}
      <Form onSubmit={formik.handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6">
            <Form.Label>Driver Name</Form.Label>
            <Form.Control
              id="driverName"
              name="driverName"
              type="text"
              placeholder="Enter Driver Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.driverName}
            />
            {formik.touched.driverName && formik.errors.driverName ? (
              <Alert>{formik.errors.driverName}</Alert>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>License Number</Form.Label>
            <Form.Control
              id="licenseNumber"
              name="licenseNumber"
              type="text"
              placeholder="Enter License Number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.licenseNumber}
            />
            {formik.touched.licenseNumber && formik.errors.licenseNumber ? (
              <Alert>{formik.errors.licenseNumber}</Alert>
            ) : null}{' '}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6">
            <Form.Label>Vehicle Number</Form.Label>
            <Form.Control
              id="vehicleNumber"
              name="vehicleNumber"
              type="text"
              placeholder="Enter vehicle number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.vehicleNumber}
            />
            {formik.touched.vehicleNumber && formik.errors.vehicleNumber ? (
              <Alert>{formik.errors.vehicleNumber}</Alert>
            ) : null}{' '}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Owner Name</Form.Label>
            <Form.Control
              id="ownerName"
              name="ownerName"
              type="text"
              placeholder="Enter Owner Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ownerName}
            />
            {formik.touched.ownerName && formik.errors.ownerName ? (
              <Alert>{formik.errors.ownerName}</Alert>
            ) : null}{' '}
          </Form.Group>
          <Form.Group
            as={Col}
            md="12"
          >
            <Form.Label>Reporting Station Name</Form.Label>
            <Form.Select
              id="reportingStationName"
              name="reportingStationName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.reportingStationName}
              onClick={()=>setFetchOptions(!fetchOptions)}
            >
              <option>Select Station Name</option>
              {stations ? (
                stations.map((station) => {
                  return (
                    <option
                      key={station.stationName}
                      value={station.stationName}
                    >
                      {station.stationName}
                    </option>
                  );
                })
              ) : (
                <option>Loading.....</option>
              )}
            </Form.Select>
            {formik.touched.reportingStationName &&
            formik.errors.reportingStationName ? (
              <Alert>{formik.errors.reportingStationName}</Alert>
            ) : null}{' '}
          </Form.Group>
        </Row>
        {loading && <Loading />}
        <Button type="submit" className="mt-2" disabled={loading}>
          Add New Vehicle
        </Button>
      </Form>
    </Container>
  );
};

export default NewVehicle;
